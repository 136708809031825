// Vuex Store
import { createStore } from 'vuex';
import { ref, getDatabase, onValue, set } from 'firebase/database';



export default createStore({
  state: {
    coins: 0,
    isAuthenticated: false,
    uid: '',
    expiryTimestamp: null,
    currentTimestamp: Math.floor(Date.now() / 1000),
    isPremium: localStorage.getItem("premium") || false,
    currentUser: null,
  },
  mutations: {
    setCoins(state, coins) {
      state.coins = coins;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },

    setexpriryTimestamp(state, expriryTimestamp) {
      state.expiryTimestamp = expriryTimestamp;
    },
    setcurrentTimestamp(state, currentTimestamp) {
      state.currentTimestamp = currentTimestamp;
    },
    setIsAuthenticated(state, status) {
      state.isAuthenticated = status;
    },
    setUid(state, uid) {
      state.uid = uid;
    },

    setIsPremium(state, premium) {
      state.isPremium = premium;
    }
  },
  actions: {
    async fetchCoins({ commit, state }) {
      try {
        if (state.uid) {
          const dbRef = ref(getDatabase(), `userCoins/${state.uid}`);
          onValue(dbRef, snapshot => {
            const coins = snapshot.val();
            commit('setCoins', coins || 0);
          });
        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    },

    async fetchCurrentTimestamp({ commit }) { // Corrected action name and parameters
      try {
        //  const response = await fetch('https://worldtimeapi.org/api/timezone/Asia/Kolkata');
        //  const data = await response.json();
        //  const currentTimestamp = data.unixtime;

        // const response = await fetch('https://timeapi.io/api/Time/current/zone?timeZone=UTC');
        // const data = await response.json();
        // const dateTimeString = data.dateTime; // e.g., "2024-08-08T04:31:24.8152449"
        // const currentTimestamp = Math.floor(new Date(dateTimeString).getTime() / 1000); // Convert to Unix timestamp
        const currentTimestamp = Math.floor(Date.now() / 1000);


        commit('setcurrentTimestamp', currentTimestamp);
      } catch (error) {
        console.error('Error fetching current timestamp:', error);
      }
    },

    async fetchExpiryTimestam({ commit, state }) {
      try {
        if (state.uid) {
          const dbRef = ref(getDatabase(), `PremiumUser/${state.uid}`);
          onValue(dbRef, snapshot => {
            const timeStamp = snapshot.val();
            commit('setexpriryTimestamp', timeStamp || 0);
          });
        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    },


    async deductCoins({ state }, { amount }) {
      try {
        if (state.uid && state.coins >= amount) {
          const dbRef = ref(getDatabase(), `userCoins/${state.uid}`);
          await set(dbRef, state.coins - amount);
          return true; // Successfully deducted coins
        } else {
          alert('Not enough coins to deduct or UID is empty');
          return false; // Failed to deduct coins
        }
      } catch (error) {
        console.error('Error deducting coins:', error);
        return false; // Failed to deduct coins
      }
    },

    async addCoins({ state, commit }, { coinsToAdd }) {
      try {
        if (state.uid) {
          // Step 1: Fetch the current coin balance from Firebase
          const dbRef = ref(getDatabase(), `userCoins/${state.uid}`);
          const snapshot = await new Promise((resolve, reject) => {
            onValue(dbRef, resolve, reject, { onlyOnce: true }); // Fetch data only once
          });
    
          const currentCoins = snapshot.val() || 0;
    
          // Step 2: Add the given amount to the fetched balance
          const newCoinBalance = currentCoins + coinsToAdd;
    
          // Step 3: Update the new balance back in Firebase
          await set(dbRef, newCoinBalance);
    
          // Update the Vuex state with the new balance
          commit('setCoins', newCoinBalance);
    
          return true; // Successfully added coins
        } else {
          alert('UID is empty');
          return false; // Failed to add coins
        }
      } catch (error) {
        console.error('Error adding coins:', error);
        return false; // Failed to add coins
      }
    },
    

    async addPremium({ state, commit }, { days }) {
      try {
        if (state.uid && state.currentTimestamp != null) {
          const currentTimestamp = state.currentTimestamp * 1000;
          const expiryTimestamp = currentTimestamp + days * 24 * 60 * 60 * 1000; // Convert days to milliseconds

          // Update the expiry timestamp in the database
          const dbRef = ref(getDatabase(), `PremiumUser/${state.uid}`);
          await set(dbRef, expiryTimestamp);

          // Update the expiry timestamp in the store
          commit('setexpriryTimestamp', expiryTimestamp);

          return true; // Successfully added premium
        } else {
          console.error('UID is empty or currentTimestamp is null');
          return false; // Failed to add premium
        }
      } catch (error) {
        console.error('Error adding premium:', error);
        return false; // Failed to add premium
      }
    },

    async fetchCurrentUser({ commit }) {
      try {
        const user = auth.currentUser;
        if (user) {
          const profile = {
            uid: user.uid,
            email: user.email,
            photoURL: user.photoURL,
            // Add other profile information here if available in auth.currentUser
          };
          commit('setCurrentUser', profile);
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    },
    setIsAuthenticated({ commit }, status) {
      commit('setIsAuthenticated', status || false);
    },
    setUid({ commit }, uid) {
      commit('setUid', uid);
    },

    setIsPremium({ commit }, premium) {
      commit('setIsPremium', premium);
    }
  }
});
