import Home from "./components/Home.vue"
import LoginPage from "./components/LoginPage.vue"
import PassBook from "./components/PassBookPrint.vue"
import PassportSizePhoto from "./components/PassportSizePhoto.vue"
import ManualAdharPrint from "./components/ManualAdharPrint.vue"
import PanCardPrint from "./components/PanCardPrint.vue"
import Contact from "./components/Contact-us.vue"
import Services from "./components/Service-details.vue"
import About from "./components/About-us.vue"
import Try from "./components/try.vue"
import BuyCoins from "./components/BuyCoins.vue"
import Profile from "./components/Profile.vue"
import ManualVoterCardPrint from "./components/manualVoterCardPrint.vue"
import AdharCardPrintOnOnePage from "./components/AdharCardPrintOnOnePage.vue"

import TermsAndConditions from "./components/TermsAndConditions.vue"
import ContactUs from './components/ContactUs.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import CancellationAndRefundPolicy from './components/CancellationAndRefundPolicy.vue';
import ShippingAndDeliveryPolicy from './components/ShippingAndDeliveryPolicy.vue';
import FailedPayment from './components/FailedPayment.vue';
import SuccessfulPayment from './components/SuccessfulPayment.vue';


import { auth } from './components/firebase'; // Assuming you have a firebase.js file where you initialize Firebase
import { createRouter, createWebHistory } from 'vue-router'



const routes = [
    {
        name: 'Home',
        component: Home,
        path: '/',
        meta: {
            title: "Home",
            auth: true

        }
    },
    {
        name: 'Login',
        component: LoginPage,
        path: '/login',
        meta: {
            title: "Login"
        }
    },
    {
        name: 'PassBook',
        component: PassBook,
        path: '/PassBook',
        meta: {
            title: "PassBook",

            auth: true
        }
    },
    {
        name: 'PassportSizePhoto',
        component: PassportSizePhoto,
        path: '/PassportSizePhoto',
        meta: {
            title: "PassportSizePhoto",
            auth: true
        }
    },
    {
        name: 'ManualAdharPrint',
        component: ManualAdharPrint,
        path: '/ManualAdharPrint',
        meta: {
            title: "ManualAdharPrint",
            hideHeader: true,
            hideFooter: true,
            auth: true

        }
    },
    {
        name: 'ManualVoterCardPrint',
        component: ManualVoterCardPrint,
        path: '/ManualVoterCardPrint',
        meta: {
            title: "ManualVoterCardPrint",
            hideHeader: true,
            hideFooter: true,
            auth: true

        }
    },

    {
        name: 'PanCardPrint',
        component: PanCardPrint,
        path: '/PanCardPrint',
        meta: {
            title: "PanCardPrint",
            auth: true


        }
    },

    {
        name: 'AdharCardPrintOnOnePage',
        component: AdharCardPrintOnOnePage,
        path: '/AdharCardPrintOnOnePage',
        meta: {
            title: "AdharCardPrintOnOnePage",
            auth: true


        }
    },
    {
        name: 'Contact-us',
        component: Contact,
        path: '/Contact-us',
        meta: {
            title: "Contact-us",


        }
    },

    {
        name: 'service-details',
        component: Services,
        path: '/service-details',
        meta: {
            title: "service-details",

        }
    },
    {
        name: 'About-us',
        component: About,
        path: '/About-us',
        meta: {
            title: "About-us",
        }
    },
    {
        name: 'try',
        component: Try,
        path: '/try',
        meta: {
            title: "try",
        }
    },
    {
        name: 'BuyCoins',
        component: BuyCoins,
        path: '/BuyCoins',
        meta: {
            title: "BuyCoins",
        }
    },
    {
        name: 'Profile',
        component: Profile,
        path: '/Profile',
        meta: {
            title: "Profile",
            auth: true
        }
    },

    {
        path: '/contact-us',
        name: 'ContactUs',
        component: ContactUs,
        meta: {
            title: "Profile",
            auth: true
        }
    },

    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: {
            title: "Profile",
            auth: true
        }
    },
    {
        path: '/shipping-and-delivery-policy',
        name: 'ShippingAndDeliveryPolicy',
        component: ShippingAndDeliveryPolicy,
        meta: {
            title: "Profile",
            auth: true
        }
    },
    {
        path: '/TermsAndConditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions,
        meta: {
            title: "Profile",
            auth: true
        }
    },
    {
        path: '/CancellationAndRefundPolicy',
        name: 'CancellationAndRefundPolicy',
        component: CancellationAndRefundPolicy,
        meta: {
            title: "Profile",
            auth: true
        }
    },
    {
        path: '/FailedPayment',
        name: 'FailedPayment',
        component: FailedPayment,
        meta: {
            title: "payment failed",
        }
    },
    {
        path: '/successful-payment',
        name: 'SuccessfulPayment',
        component: SuccessfulPayment,
        meta: {
            title: "validate payment",
           
        }
    },





]

const router = createRouter({
    history: createWebHistory(),
    routes
});

function isAuthenticated() {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            unsubscribe(); // Unsubscribe from the listener once resolved
            resolve(user); // Resolve with the user object
        });
    });
}

router.beforeEach(async (to, from, next) => {
    document.title = `${to.meta.title}`;

    // Check if the route requires authentication
    if (to.meta.auth) {
        // Check if the user is authenticated
        const user = await isAuthenticated();
        if (!user) {
            // If the user is not authenticated, redirect to the login page
            next({ name: 'Login' });
        } else {
            // If the user is authenticated, allow access to the route
            next();
        }
    } else {
        // If the route does not require authentication, allow access
        next();
    }
});
// router.beforeEach((to, from, next) => {

//     const currentUser = auth.currentUser(); // Get the current user

//     // Check if the route requires authentication and currentUser is null
//     if (to.meta.auth && !currentUser) {
//         // Redirect to the login page if currentUser is null
//         next({ name: 'Login' });
//     } else {
//         // Allow access to the route
//         next();
//     }

//     // document.title = `${to.meta.title}`;
//     // next();

// });

export default router;