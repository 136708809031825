<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100">
    <div class="max-w-lg w-full bg-white p-8 rounded-lg shadow-md text-center">
      <h1 class="text-3xl font-bold mb-4">Payment Successful!</h1>
      <p class="text-gray-700 mb-6">Thank you for your purchase. Your subscription has been activated.</p>
      <p class="text-gray-700 mb-6">Close this and open the mobile application / इसको बंद करके मोबाइल ऐप खोलें</p>
      <button @click="closeApplication"
        class="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-full">
        Close Application
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';


export default {
  data() {
    return {
      amount: localStorage.getItem("amount") || "65",
      userId: localStorage.getItem("userId")
    };
  },

  async mounted() {
    this.$store.dispatch('setUid', this.userId);
    await this.fetchCurrentTimestamp()
    this.checkPaymentStatus();
  },

  computed: {
    ...mapState(['coins', 'isPremium', 'currentTimestamp']),
  },

  methods: {
    ...mapActions(['deductCoins', 'addCoins', 'addPremium', 'fetchCurrentTimestamp']),

    checkPaymentStatus() {
      const urlParams = new URLSearchParams(window.location.search);
      const paymentStatus = urlParams.get('paymentStatus');
      const transactionIdFromServer = urlParams.get('transactionId');
      const randomVerificationIdFromServer = urlParams.get('randomVerificationId');
      const transactionId = localStorage.getItem("transactionId");
      const randomVerificationId = localStorage.getItem("randomVerificationId");

      // Verify if the payment status is successful and the transaction ID matches
      if (paymentStatus === 'success' && transactionId === transactionIdFromServer && randomVerificationId === randomVerificationIdFromServer) {
        
        switch (this.amount) {
          case "130":
            this.addCoins(200)
            break;
          case "65":
            this.addPremium(7)
            break;
          case "120":
            this.addPremium(15)
            break;
          case "150":
            this.addPremium(30)
            break;
          case "370":
            this.addPremium(90)
            break;
          case "650":
            this.addPremium(180)
            break;
          case "1100":
            this.addPremium(360)
            break;
          default:
            this.addPremium(7)
            break;
        }
        // Store a new unique transaction ID in localStorage to prevent duplicate activations
        localStorage.setItem("transactionId", Date.now().toString() + "chu");
        localStorage.setItem("randomVerificationId", Date.now().toString() + "ghanta");
      } else {
        localStorage.setItem("transactionId", Date.now().toString() + "chu");
        localStorage.setItem("randomVerificationId", Date.now().toString() + "ghanta");
        this.$router.push('/FailedPayment');
      }
    },

    async addPremium(days) {
      try {
        const addedResponse = await this.$store.dispatch('addPremium', { days });
        if (!addedResponse) {
          this.$router.push('/FailedPayment');
        }
      } catch (error) {
        console.error('Error activating premium:', error);
        alert("Error activating subscription. Try again later or contact support.");
      }
    },
    async addCoins(coinsToAdd) {
      try {
        const coinsAdded = await this.$store.dispatch('addCoins', { coinsToAdd });
        if (!coinsAdded) {
          alert('Failed to add coins');
        }
      } catch (error) {
        console.error('Error adding coins:', error);
        alert("Error adding coins. Try again later or contact support.");
      }
    },


    closeApplication() {
      window.close(); // Attempt to close the current window/tab
      // You can display a message if the window can't be closed by the browser:
      if (!window.closed) {
        alert("You can close this tab or window manually.");
      }
    }
  }
};
</script>

<style scoped></style>
